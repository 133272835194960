<script lang='ts'>
  import { onDestroy, tick } from 'svelte'
  import { link, navigate } from 'svelte-routing'

  import { EXECUTION_TIME_HACK, MS_PER_SEC, RATING, TESTS } from '@/config/constants'

  import { getInterfaceLanguageId } from '@/helpers/apiCall'
  import { diff } from '@/helpers/diff'
  import { logOnServer, playAudio } from '@/helpers/errorsHelper'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { updatePoints } from '@/helpers/game'
  import { _ } from '@/helpers/i18n'
  import { getUniqueObjectsByKey } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'
  import { removePostSyntax } from '@/helpers/removePostSyntax'
  import { disableForm, getBreads, initFocus, isAudioQuestion, isCustomTestType, isSentenceQuestion, isWordQuestion } from '@/helpers/testHelpers'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import IconCheckCircle from "@/components/icons/phosphor/IconCheckCircle.svelte";
  import IconClock from "@/components/icons/phosphor/IconClock.svelte";
  import IconDotsThreeVertical from "@/components/icons/phosphor/IconDotsThreeVertical.svelte";
  import IconEyeSlash from "@/components/icons/phosphor/IconEyeSlash.svelte";
  import IconNote from "@/components/icons/phosphor/IconNote.svelte";
  import IconPaperPlaneTilt from "@/components/icons/phosphor/IconPaperPlaneTilt.svelte";
  import IconXCircle from "@/components/icons/phosphor/IconXCircle.svelte";
  import EditMnemo from '@/components/lesson/EditMnemo.svelte'
  import ExampleSentences from '@/components/lesson/ExampleSentences.svelte'
  import CorrectionController from '@/components/task/CorrectionController.svelte'
  import AnswerButtons from '@/components/test/AnswerButtons.svelte'
  import ProgressIndicator from '@/components/test/ProgressIndicator.svelte'
  import RadioQuestions from '@/components/test/RadioQuestions.svelte'
  import TestAlerts from '@/components/test/TestAlerts.svelte'
  import TestTitle from '@/components/test/TestTitle.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'
  import Loader from '@/components/ui/Loader.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'
  import ReportButton from '@/components/ui/ReportButton.svelte'

  import { AdditionalCheck, Bread, CheckSolutionnedApiData, CombinedApiData, TestQuestion } from '@/definitions/langoid'
  import { TestAlert } from '@/definitions/test/api'
  import { userStorredConfig } from '@/store'

  export let iso = '',
    batchType = 'words'
  if (location.pathname.includes('refresh')) {
    batchType = location.href.split('/').reverse()[1]
  }

  const dontKnowString = 'I DØN\'T KNǒW!'
  const errorMessage = ''
  const elements: HTMLInputElement[] = []

  let vocabularyIsEmpty = false
  let questions: TestQuestion[] = []
  let testSettings: CombinedApiData['testSettings']
  let answerContent = { example: '', mnemo: '', sentences: [], words: [] } as AdditionalCheck
  let alerts: TestAlert[] = []
  let breads: Bread[] = getBreads(location.pathname, { $_, iso }) || []

  let currentQuestion = 0
  let sum = 0
  let all = 0
  let correctAnswers = 0
  let wrongAnswers = 0
  let passedQuestions: number
  let progress: number = 1
  let interfaceId = 0
  let timeDiff: number // used to calculate difference between start and end of test
  let time = 0

  let answered = false
  let optionBtnHidden = true
  let showStopWatch = false
  let iKnewThisDisabled = false
  let selected: boolean
  let isAnswerSaved = false

  let answerStatusClass = ''
  let solution = ''
  let inputValue = ''
  let sentenceDiff = ''

  let lastCallTimestamp = 0

  async function loadQuestionsLimited () {
    const now = Date.now()
    if ((now - lastCallTimestamp) > 1000) { // Throttle the calls to once per second
      lastCallTimestamp = now
      await loadQuestions()
    }
  }

  $: passedQuestions = 1 + currentQuestion

  $: {
    const remainingQuestions = sum - questions.length
    const passed = passedQuestions + remainingQuestions
    progress = calculateProgress(passed, sum, questions.length)
    if (currentQuestion === sum) {
      wrongAnswers = progress === correctAnswers ? 0 : progress - correctAnswers
    } else {
      wrongAnswers = progress === correctAnswers ? 0 : progress - 1 - correctAnswers
    }
  }

  function calculateProgress (passed: number, sum: number, questionsLength: number): number {
    if (passed > sum) {
      return sum
    }
    if (passed > 0) {
      return passed
    }
    sum = questionsLength
    return 1
  }

  const unsubscribe = userStorredConfig.subscribe((config) => {
    interfaceId = config.id_lang_interface
    if (config.id_lang_interface > 1 && questions.length === 0) {
      loadQuestionsLimited()
    }
  })
  setTimeout(async () => {
    if (interfaceId <= 1) {
      interfaceId = getInterfaceLanguageId()
      await loadQuestions()
    }
  }, EXECUTION_TIME_HACK)

  function startTimer () {
    timeDiff = Date.now()
    showStopWatch = true
  }

  const stopWatchInterval = 10
  const interval = setInterval(() => {
    if (showStopWatch && !answered) {
      time = Date.now() - timeDiff
    }
  }, stopWatchInterval)

  onDestroy(() => {
    clearInterval(interval)
    unsubscribe()
  })

  async function loadQuestions () {
    breads = getBreads(location.pathname, { $_, iso })
    const data: CombinedApiData = await fetchData('test/load', {
      interfaceId,
      type: batchType
    })
    currentQuestion = 0
    wrongAnswers = 0
    if (data.error) {
      if (data.error === 'testsPage.emptyUserVocabulary') {
        vocabularyIsEmpty = true
      } else {
        notifier.warning($_(data.error))
      }
      return
    }
    questions = data.questions || []
    if (questions.length === 0) {
      vocabularyIsEmpty = true
    }
    sum = data.sum
    all = data.all
    if (sum > questions.length) {
      correctAnswers = data.correctAnswers
    }
    testSettings = data.testSettings
    startTimer()
    setTimeout(focusQuestion, EXECUTION_TIME_HACK)
  }

  async function checkSolution (markAsIDontKnow = false) {
    const current: TestQuestion | undefined = questions ? questions[currentQuestion] : undefined
    if (!current) {
      notifier.error('Unknown error')
      return
    }
    const el = elements[currentQuestion]
    const id = current.id
    const answer = markAsIDontKnow ? dontKnowString : (([TESTS.RADIO].includes(current.type) ? el : el.value) || '')
    if (!markAsIDontKnow) {
      questions[currentQuestion].answered = true
    }

    const apiParams = { answer, id, progress, sum: questions.length, time, type: batchType }
    const data: CheckSolutionnedApiData = await fetchData('test/checkSolution', apiParams)

    if (!data.correct && data.other?.correct) {
      notifier.warning($_('testsPage.halfCorrect'), { duration: 7e3 })
      return ''
    }

    if ([TESTS.TRANSLATE_SENTENCE, TESTS.AUDIO_SENTENCE, TESTS.CUSTOM_SENTENCE].includes(current.type)) {
      if (data.diff === dontKnowString) {
        data.diff = ''
      }
      sentenceDiff = diff(data.diff || '', data.solution || '')
    }

    updatePoints(data.points, data.level)

    answered = true
    sum = data.sum
    correctAnswers = data.correctAnswers
    alerts = Object.values(data.alerts || {}) || []
    disableForm(document.getElementById('question-' + currentQuestion))
    answerStatusClass = data.correct ? '-correct' : '-wrong'
    solution = data?.solution
    if (data.additional) {
      answerContent = data.additional
    }
    if (data.correct) {
      notifier.success($_('testsPage.correct'))
      if (testSettings.skipCorrect) {
        nextQuestion()
      }
    }
  }

  function resetTimers () {
    const oldTimeDiff = timeDiff
    timeDiff = Date.now()
    time = timeDiff - oldTimeDiff
  }

  function nextQuestion () {
    iKnewThisDisabled = false
    answerStatusClass = ''
    answerContent = { example: '', mnemo: '', sentences: [], words: [] }
    optionBtnHidden = true
    currentQuestion = currentQuestion + 1
    focusQuestion()
    answered = false
    sentenceDiff = ''
    inputValue = ''
    selected = false
    resetTimers()
  }

  async function focusQuestion () {
    if (questions.length === 0) {
      return
    }
    const type = questions[currentQuestion]?.type || 0
    if (type === 0) {
      return
    }
    const questionForm: HTMLElement | null = document.getElementById(`question-${currentQuestion}`)
    const isAudio = isAudioQuestion(type)
    const isCustomQuestion = isCustomTestType(type)

    if (isAudio && !isCustomQuestion && !questions[currentQuestion]?.audio) {
      const question = questions[currentQuestion] || {}
      const errorMessage = `CONTENT: No audio for question type: ${type} id:${question.id} id_word:${question.id_word}`
      logOnServer(errorMessage)
    }

    if (questionForm) {
      if (isAudio) {
        await tick() // Play audio after wrong answer will not work without this
        playAudio(questionForm.querySelector('audio'), 'testsTranslateAudio')
      }
    }
  }

  async function iKnowThis () {
    const data = await fetchData('test/iKnowThis', {
      testId: questions[currentQuestion].id, type: batchType
    })
    correctAnswers = data.correctAnswers
    nextQuestion()
  }

  async function buryWord (period = 'forever') {
    await fetchData('test/buryWord', { period, testId: questions[currentQuestion].id })
    nextQuestion()
  }

  async function iKnewThis () {
    const data = await fetchData('test/iKnewThis', {
      progress, sum, testId: questions[currentQuestion].id, time, type: batchType
    })
    correctAnswers = data.correctAnswers
    wrongAnswers = wrongAnswers - 1
    iKnewThisDisabled = true
    optionBtnHidden = true // Nisam siguran da li ovde sme da se pozove cela nextQuestion() funkcija ???
  }

  function iDontKnow () {
    optionBtnHidden = true
    checkSolution(true)
  }

  // Setting manual rating in case of expert or advanced rating enabled
  async function setRating (rating: number, session: 'initial' | 'repeat' = 'initial') {
    const current: TestQuestion | undefined = questions ? questions[currentQuestion] : undefined
    if (!current) {
      notifier.error('Unknown error')
      return
    }
    isAnswerSaved = true
    const data: CheckSolutionnedApiData = await fetchData('test/checkSolution', {
      answer: rating.toString(),
      id: current.id,
      progress,
      session,
      sum: questions.length,
      time,
      type: batchType
    })
    questions[currentQuestion].answered = true
    answered = true
    sum = data.sum
    correctAnswers = data.correctAnswers
    alerts = Object.values(data.alerts || {}) || []
    disableForm(document.getElementById('question-' + currentQuestion))
    const MIN_CORRECT_RATING = 3
    const correct = rating >= MIN_CORRECT_RATING
    answerStatusClass = correct ? '-correct' : '-wrong'
    solution = data?.solution
    if (data.additional) {
      answerContent = data.additional
    }
    if (correct && testSettings.skipCorrect) {
      nextQuestion()
    } else if (session === 'repeat') {
      nextQuestion()
    }
    updatePoints(data.points, data.level)
    isAnswerSaved = false
  }

  function handleKeydown (e: KeyboardEvent) {
    if (questions.length === 0) {
      return
    }
    const keyPressed = e.key
    const type = questions[currentQuestion]?.type || 0
    if (type === 0) {
      return
    }
    const targetElement = e.target as HTMLElement
    const targetTagName = targetElement.tagName
    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
      if ((targetTagName === 'INPUT' && (targetElement as HTMLInputElement).type === 'text') && !e.ctrlKey || targetTagName === 'TEXTAREA') {
        return // prevent running this code when user is typing
      }
    }

    if (['0', '1', '2', '3', '4', '5'].includes(e.key)) {
      e.preventDefault()
      if (e.ctrlKey) {
        if (e.key === '5') {
          iKnowThis()
          return
        } else if (e.key === '3') {
          iKnewThis()
          return
        } else if (e.key === '0') {
          iDontKnow()
          return
        }
      }
      const mode = testSettings?.mode
      const session = answered ? 'repeat' : 'initial'
      const key = Number(e.key)
      if (key >= RATING.COMPLETE_BLOCKOUT && key <= RATING.CORRECT_EASY_AND_FAST) {
        if (mode === 'advanced' && [RATING.COMPLETE_BLOCKOUT, RATING.CORRECT_BUT_VERY_HARD, RATING.CORRECT_EASY_AND_FAST].includes(key)) {
          setRating(key, session)
        } else if (mode === 'expert') {
          setRating(key, session)
        }
      }
    }
    if (type === TESTS.RADIO && !['expert', 'advanced'].includes(testSettings?.mode)) {
      if (['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(keyPressed)) {
        const selector: string = `input[name="answer_${questions[currentQuestion].id}"]`
        const els = document.querySelectorAll(selector)
        const el = els[parseInt(keyPressed) - 1] as HTMLInputElement
        if (el) {
          el.click()
          el.checked = true
        }
        e.preventDefault()
      }
    }
  }

  function handleKeyPress (e: KeyboardEvent) {
    if (e.key === 'Enter') {
      const targetEvent = e.target as HTMLElement
      if (targetEvent.tagName === 'BUTTON') {
        // this is to prevent skipping when click iKnowThis, iKnewThis...
        return
      }
      if (typeof questions[currentQuestion] === 'undefined') {
        return
      }
      if (questions[currentQuestion].answered) {
        if (passedQuestions >= questions.length) {
          navigate(`/${iso}/tests/${batchType}` + (location.pathname.includes('refresh') ? '' : '/refresh'))
        } else if (passedQuestions < questions.length) {
          nextQuestion()
        }
      }
    }
  }
</script>

<svelte:window on:keydown={handleKeydown} on:keypress={handleKeyPress} />

<Breadcrumb {breads}>
  <div slot='progressBar'>
    <ProgressBar clickable={false} maxProgress={sum} {progress} showNumbers variant='showMobile' />
  </div>
  <div slot='pageHelperIcons' class='_breadcrumbicons'>
    {#if answerContent?.words?.[0]?.id}
      <EditMnemo idWord={answerContent.words[0].id} kebabItem bind:mnemo={answerContent.mnemo} />
      <hr class='_mobileOnly' />
    {/if}
    {#if answerContent.id}
      <EditMnemo idWord={answerContent.id} kebabItem bind:mnemo={answerContent.mnemo} />
      <hr class='_mobileOnly' />
    {/if}
    {#if questions?.[currentQuestion]}
      {@const question = questions[currentQuestion] || {}}
      <ReportButton category={isWordQuestion(question.type)? 'test_question':'test_sentence'} kebabItem rel={question.id} bind:selected />
      <hr class='_mobileOnly' />
    {/if}
    <PageInfo kebabItem pageName='tests' />
  </div>
</Breadcrumb>
{#if questions.length}
  <ProgressBar clickable={false} maxProgress={sum} {progress} showNumbers variant='showDesktop' />
{/if}
<div class='_gap24'>
  {#each questions as question, id}
    {@const type = question.type}
    {#if currentQuestion === id}
      <form
        id='question-{id}'
        class={`question-form ${answerStatusClass}`}
        class:-active={currentQuestion === id}
        on:submit|preventDefault={() => checkSolution()}
      >
        <div class='question-block {answerStatusClass}'>
          <TestTitle {answerStatusClass} {type} />
          <div class='question-and-input' class:-answer={answerStatusClass}>
            <div class='question-main {answerStatusClass}'>
              {#if isAudioQuestion(type)}
                <div>
                  <AudioPlayer forAutoPlay src={question.audio} variant='big' />
                </div>
              {:else if type === TESTS.TRANSLATE_SENTENCE}
                <div>{removePostSyntax(question?.sentence || '')}</div>
              {:else if isCustomTestType(type)}
                <h1>{question.word || question.question}</h1>
              {:else if isWordQuestion(type) && type !== TESTS.TRANSLATE_AUDIO}
                <h1>{question.word}</h1>
              {/if}
              {#if question.pos}
                <div class='pos'>{$_('pos.' + question.pos)}</div>
              {/if}
            </div>
            {#if type === TESTS.RADIO && answerStatusClass !== '-wrong'}
              <RadioQuestions {id} {checkSolution} {elements} {question} />
            {/if}
            {#if type !== TESTS.RADIO && testSettings.showInputField && !answered}
              <div class='input-section'>
                <input
                  bind:this={elements[id]}
                  class='inputAnswer'
                  autocomplete='off'
                  placeholder={$_('mix.writeHere')}
                  type='text'
                  bind:value={inputValue}
                  use:initFocus
                  name='answer_{question.id}'
                />
                <button
                  class='enterBtn'
                  class:-send={inputValue !== ''}
                  tabindex='-1'
                  type='button'
                  on:click={() => checkSolution()}
                >
                  <IconPaperPlaneTilt weight='fill' />
                </button>
              </div>
            {/if}
          </div>
        </div>
        <div class='extra-info'>
          {#if testSettings?.showTimer}
            <div class='timer-block'>
              <IconClock weight='regular' />
              {(time / MS_PER_SEC).toFixed(1)} s
            </div>
          {/if}
          {#if answerContent.mnemo}
            <div class='inline-mnemo'>
              <IconNote weight='regular' />{answerContent.mnemo}</div>
          {/if}
        </div>
        <div class='errorMessage'>{errorMessage}</div>
        {#if answered && (answerStatusClass === '-wrong' || !testSettings.skipCorrect)}
          <div class='answer-block'>
            <div class='solution-indicator {answerStatusClass}'>
              {#if answerStatusClass === '-wrong'}
                <IconXCircle weight='fill' />
                <div class='userAnswer'>
                  {#if inputValue}
                    {type === TESTS.RADIO ? elements[id] : inputValue}
                  {:else}
                    {$_('testsPage.wrongAnswer')}
                  {/if}
                </div>
              {:else}
                <IconCheckCircle weight='fill' />
                {$_('testsPage.correct')}
              {/if}
            </div>
            {#if alerts.length > 0}
              <TestAlerts {alerts} {batchType} />
            {/if}
            <div class='message-wrapper -correct'>
              <div class='solution-example'>
                {#if isSentenceQuestion(type)}
                  {#if inputValue && answerStatusClass === '-wrong'}
                    <CorrectionController>
                      {@html sentenceDiff}
                    </CorrectionController>
                  {:else}
                    {solution}
                  {/if}
                {:else if isCustomTestType(type)}
                  <div class='testSolution'>
                    {#if type === TESTS.CUSTOM_SENTENCE}
                      {#if inputValue}
                        <CorrectionController>
                          {@html diff(inputValue, solution)}
                        </CorrectionController>
                      {:else}
                        {solution}
                      {/if}
                    {:else}
                      <div>{solution}</div>
                      {#if answerContent.example}
                        <div class='definition'>{answerContent.example}</div>
                      {/if}
                    {/if}
                    {#if answerContent.mnemo}
                      <div class='mnemo'>{answerContent.mnemo}</div>
                    {/if}
                  </div>
                {:else if isWordQuestion(type)}
                  <div class='test-solution-wrapper _row'>
                    <div class='test-solution _row'>
                      {#each getUniqueObjectsByKey(answerContent.words, 'id') as word}
                        <AudioPlayer src={word.audio} variant='middle' />
                      {/each}
                      <div class='testSolution'>{answerContent.words[0].translation}</div>
                    </div>
                    <span class='native'>{solution}</span>
                    <span class='meta'>{$_('pos.' + question.pos)} • {question.level}</span>
                  </div>
                  <div class='solution-details'>
                    {#if answerContent.sentences && answerContent.sentences.length}
                      <ExampleSentences sentences={answerContent.sentences} showTitle />
                    {/if}
                  </div>
                {:else}
                  ERROR
                {/if}
              </div>
            </div>
          </div>
        {/if}
        <div class='test-additional-controls {answerStatusClass}'>
          {#if answered && passedQuestions < questions.length}
            <button type='button' on:click={nextQuestion}>{$_('testsPage.next')}</button>
          {/if}
          <AnswerButtons {iDontKnow} {isAnswerSaved} mode={testSettings?.mode} session={answered?'repeat':'initial'} {setRating} />
          {#if batchType !== 'sentences'}
            <button class='_icon' type='button' on:click={() => {optionBtnHidden = !optionBtnHidden}}>
              <IconDotsThreeVertical weight='bold' />
            </button>
            <div class='hidden-buttons' class:-optionBtnHidden={optionBtnHidden}>
              {#if answered}
                {#if answerStatusClass === '-wrong'}
                  <button
                    class='_secondary-btn _maxwidth-btn'
                    disabled={iKnewThisDisabled}
                    title='CTRL + 3'
                    type='button'
                    on:click={iKnewThis}
                  >{$_('testsPage.iKnewThis')}</button>
                {/if}
              {:else}
                <button
                  class='_secondary-btn _maxwidth-btn'
                  title='CTRL + 5'
                  type='button'
                  on:click={iKnowThis}
                >{$_('testsPage.iKnowThis')}</button>
              {/if}
              <button class='_transparent-blue' type='button' on:click={() => buryWord('forever')}>
                <IconEyeSlash weight='regular' />{$_('testsPage.buryWord')}</button>
              <div class='bury-label'>{$_('testsPage.buryForDays')}</div>
              <div class='bury-days'>
                <button type='button' on:click={() => buryWord('day')}>1</button>
                <button type='button' on:click={() => buryWord('week')}>7</button>
                <button type='button' on:click={() => buryWord('month')}>30</button>
              </div>
            </div>
          {/if}
        </div>
      </form>
    {/if}
  {/each}
  {#if questions.length === 0}
    {#if vocabularyIsEmpty}
      <EmptyState title={$_('vocabularyPage.emptyVocabulary')}>
        <p>{$_('testsPage.noTests')}</p>
        <a href='/{iso}/lessons' use:link>{$_('vocabularyPage.goToLessons')}</a>
      </EmptyState>
    {:else}
      <h2>{$_('testsPage.loadingQuestions')}</h2>
      <Loader />
    {/if}
  {/if}

  <div class='load-more'>
    {#if answered || passedQuestions > questions.length}
      {#if !(passedQuestions < questions.length || questions.length === 0)}
        <button
          class='loadMore'
          type='button'
          on:click={() => navigate(`/${iso}/tests/${batchType + (location.pathname.includes('refresh') ? '' : '/refresh')}`)}
        >{$_('testsPage.loadMoreQuestions')}</button>
        {$_('mix.or')}
        <a class='continueLearning' href='/{iso}/' use:link>{$_('testsPage.continueLearning')}</a>
      {/if}
    {/if}
  </div>
  <hr />
  <div class='combined-meta'>
    {#if questions.length > 0}
      {#if answered}
        <ProgressIndicator
          correct={correctAnswers + (answerStatusClass === '-correct' ? 1 : 0)}
          wrong={wrongAnswers + (answerStatusClass === '-wrong' ? 1 : 0)}
        />
      {:else}
        <ProgressIndicator correct={correctAnswers} wrong={wrongAnswers} />
      {/if}
    {/if}
    <div class='solvingText'>
      {#if batchType !== 'sentences'}
        {@html sum > all
          ? ''
          : $_('testsPage.solvingXofY', {
            values: {
              all: `<strong>${all}</strong>`,
              sum: `<strong>${sum}</strong>`
            }
          })}
      {/if}
    </div>
  </div>
</div>

<style lang='scss'>
  .answer-block {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    &:empty {
      display: none;
    }
  }

  .message-wrapper, .solution-indicator {
    display: flex;
    gap: 0.8rem;
    width: 100%;
    padding: 0.8rem 1.2rem;
  }

  .extra-info {
    display: flex;
    gap: 2.4rem;
  }

  .inline-mnemo, .timer-block {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    justify-content: center;
  }

  .message-wrapper {
    &.-wrong {
      align-items: center;
      background: var(--error-background);
      border: 0.1rem solid var(--error-text);
      border-radius: 0.8rem;

      > .userAnswer {
        width: 100%;
        margin: 0 auto;
        font: var(--Semibold-400);
        color: var(--Error-Medium);
      }
    }

    &.-correct {
      position: relative;
      flex-direction: column;
      gap: 2.4rem;
      min-width: 70%;
      padding: 1.6rem;
      background: var(--success-background);
      border-right: 0.2rem solid var(--success-text);
      border-left: 0.2rem solid var(--success-text);
    }
  }

  .solution-indicator {
    align-items: center;
    border-radius: 0.8rem;

    &.-wrong {
      color: var(--error-text);
      background: var(--error-background);
      border: 0.1rem solid var(--error-text);
    }

    &.-correct {
      color: var(--success-text);
      background: var(--success-background);
      border: 0.1rem solid var(--success-text);
    }
  }

  .load-more {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .loadMore, > .continueLearning {
      padding: 0.8rem 8rem;
    }

    > .continueLearning {
      background-color: var(--Base-White);
      border: 0.2rem solid var(--Primary-Medium);
      border-radius: 0.8rem;
    }
  }

  .input-section {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 2rem;

    > .enterBtn {
      position: absolute;
      top: 0.4rem;
      right: 0.4rem;
      width: 3.2rem;
      height: 3.6rem;
      padding: 0;
      color: var(--Primary-Medium);
      background: transparent;
      border: none;
      border-radius: 0;
      opacity: 0.6;

      &.-send {
        opacity: 1;
      }
    }
  }

  .bury-label {
    max-width: 12rem;
  }

  .bury-days {
    display: flex;
    gap: 0.4rem;
    align-content: center;
    align-items: center;
    text-align: center;

    > button {
      display: inline-block;
      min-width: 4rem;
      height: 3.2rem;
      padding: 0 0.4rem;
      text-align: center;
      color: var(--Primary-Medium);
      background-color: var(--Base-White);
      border: solid var(--Primary-Medium) 0.1rem;
      border-radius: 0.4rem;
    }
  }

  @media (max-width: 768px) {
    .message-wrapper > .userAnswer {
      text-align: left;
    }
  }
</style>
